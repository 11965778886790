import { createStyles } from "@mantine/styles";
import { Box, Text } from "@mantine/core";
import animation from "../../animations/27 - Business cooperation.json";
import Lottie from "lottie-react";
import useLanguage from "../../hooks/UseLanguage";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column-reverse",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
    gap: "20px",
    justifyContent: "center",
    textAlign: "left",
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
  },

  title: {
    zIndex: 0,
    fontSize: "3rem",
    fontWeight: 700,

    [theme.fn.smallerThan("lg")]: {
      fontSize: "2rem",
    },
  },

  text: {
    zIndex: 0,
    fontSize: "1.7rem",
    fontWeight: 700,

    [theme.fn.smallerThan("lg")]: {
      fontSize: "1.2rem",
    },
  },
}));

const languages = {
  kaz: {
    title: "Сіздің пайдаңызды арттырамыз",
    description:
      "Біз клиенттерді жалбызу және доходты арттыру үшін сіздің саланыңызды және конкуренттеріңізді есепке ала отырып, сапалы сайттар мен бағдарламалар жасаймыз.",
  },
  rus: {
    title: "Увеличим вашу прибыль",
    description:
      "Мы создаем качественные сайты и приложения, учитывая вашу отрасль и конкурентов, чтобы привлечь клиентов и повысить доходы.",
  },
};

export function WeSaveMoney() {
  const { classes } = useStyles();
  const { language } = useLanguage();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Text
          className={classes.title}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan", deg: 75 }}
        >
          {languages[language].title}
        </Text>
        <Text className={classes.text}>{languages[language].description}</Text>
      </Box>
      <Lottie animationData={animation} />
    </Box>
  );
}
