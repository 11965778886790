import { Text, createStyles, Button, Box } from "@mantine/core";
import { useHover } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  button: {
    borderWidth: "5px",
    color: "black",
    height: "100%",
    "&:hover": {
      backgroundColor: "black",
    },
  },

  button_box: {
    width: "300px",
    height: "100%",
    position: "relative",
    padding: "4px",
    backgroundColor: "white",
    borderRadius: theme.radius.md,

    "&:hover": {
      backgroundImage: "linear-gradient(135deg, #3746A4, #07E8F4)",
      boxShadow: "0px 0px 32px 16px rgba(34,139,198,0.25)",
    },
  },

  text: {
    color: "white",
  },
  text_hovered: {
    backgroundImage: theme.fn.gradient({ from: "indigo", to: "cyan", deg: 45 }),
    "-webkit-background-clip": "text",
    color: "transparent",
  },
}));

export function OutlineGradientButton({
  text,
  targetId,
}: {
  text: string;
  targetId: string;
}) {
  const { classes, cx } = useStyles();
  const { hovered, ref } = useHover();

  return (
    <div ref={ref}>
      <Box className={classes.button_box}>
        <Button
          className={classes.button}
          onClick={(event) => {
            event.preventDefault();
            setTimeout(() => {
              window.scrollTo({
                top: document.getElementById(targetId)!.offsetTop - 100,
                behavior: "smooth",
              });
            }, 100);
          }}
          fullWidth
          variant="filled"
          bg={"black"}
        >
          <Text
            className={cx(hovered ? classes.text_hovered : classes.text)}
            align="center"
            w={"100%"}
            size="1.6rem"
            weight={700}
          >
            {text}
          </Text>
        </Button>
      </Box>
    </div>
  );
}
