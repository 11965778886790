import {
  Box,
  Center,
  Container,
  createStyles,
  Flex,
  Text,
  SimpleGrid,
  rem,
} from "@mantine/core";
import { ImageCard } from "./ImageCard";
import PaperLabImage from "../images/paperlab.png";
import ComportalImage from "../images/comportal.png";
import SaqtaImage from "../images/saqta.png";
import ImbotImage from "../images/imbot.png";
import Ashyqaspan from "../images/ashyqaspan.png";
import Assalam from "../images/assalam.png";
import Maidah from "../images/maidah.png";
import QQQ from "../images/qqq.png";
import YesCoffee from "../images/yes-coffee.png";
import MagnusImage from "../images/it-magnus.png";
import useLanguage from "../hooks/UseLanguage";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    justifyContent: "center",
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.2,
    textAlign: "center",
    fontSize: "3rem",
    fontWeight: 700,
    zIndex: 0,
  },
}));

const languages = {
  kaz: { title: "Біздің жобалар" },
  rus: { title: "Наши проекты" },
};

export function Partners() {
  const { classes } = useStyles();
  const { language } = useLanguage();
  return (
    <Box id="projects" className={classes.wrapper}>
      <Text
        className={classes.title}
        variant="gradient"
        gradient={{
          from: "indigo",
          to: "cyan",
          deg: 75,
        }}
      >
        {languages[language].title}
      </Text>
      <SimpleGrid
        cols={3}
        spacing="xl"
        breakpoints={[
          { maxWidth: "80rem", cols: 2, spacing: "md" },
          { maxWidth: "56rem", cols: 1, spacing: "sm" },
        ]}
      >
        <ImageCard image={PaperLabImage} link={"https://pikirlab.kz"} />
        <ImageCard image={ComportalImage} link={"https://esd.comportal.kz"} />
        <ImageCard image={SaqtaImage} link={"https://saqta.kz"} />
        <ImageCard image={MagnusImage} link={"https://itmagnus.kz"} />
        <ImageCard image={Ashyqaspan} link={"https://ashyqaspan.kz"} />
        <ImageCard image={Assalam} link={"https://assalam.kz"} />
        <ImageCard
          image={Maidah}
          link={"https://maidah.kz/espresso_day/stores/silkway/menu"}
        />
        <ImageCard image={QQQ} link={"https://qqq.com.kz"} />
        <ImageCard image={YesCoffee} link={"https://yes-coffee.kz"} />
        <ImageCard image={ImbotImage} link={"https://imbot.kz"} />
      </SimpleGrid>
    </Box>
  );
}
