import {
  createStyles,
  Container,
  Title,
  Button,
  Text,
  rem,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    zIndex: 0,
  },

  container: {
    zIndex: 0,
  },

  content: {
    maxWidth: rem(680),
    marginRight: `calc(${theme.spacing.xl} * 3)`,
    zIndex: 0,
    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,
    zIndex: 0,
    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    zIndex: 0,
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,
    zIndex: 0,
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    zIndex: 0,
    backgroundImage: theme.fn.gradient({ from: "indigo", to: "cyan", deg: 45 }),
    "-webkit-background-clip": "text",
    color: "transparent",
  },

  highlight_small: {
    zIndex: 0,
    backgroundImage: theme.fn.gradient({
      from: "#2974BB",
      to: "#228BC6",
      deg: 45,
    }),
    "-webkit-background-clip": "text",
    color: "transparent",
  },
}));

export function CompanySection() {
  const { classes } = useStyles();
  return (
    <div id="company-page" className={classes.inner}>
      <div className={classes.content}>
        <Title className={classes.title}>
          Мы, <span className={classes.highlight}>Tech Steppe</span>,
          специализируемся на аутсорсинге и запуске стартапов
        </Title>
        <Text color="dimmed" mt="md" size={"1.3rem"}>
          Предоставляем услуги по разработке{" "}
          <span className={classes.highlight_small}>мобильных</span> и{" "}
          <span className={classes.highlight_small}>веб-приложений</span>,{" "}
          <span className={classes.highlight_small}>игр</span> и{" "}
          <span className={classes.highlight_small}>VR/AR</span> решений. Наша
          команда принимает проекты любой сложности, гарантируя высокое качество
          и инновационные подходы. Мы помогаем клиентам внедрять передовые
          технологии для расширения бизнеса, предлагая быстрые сроки и
          уникальный дизайн.
        </Text>

        <Button
          variant="gradient"
          gradient={{ deg: 133, from: "blue", to: "cyan" }}
          size="lg"
          radius="md"
          mt="xl"
          onClick={(event) => {
            event.preventDefault();
            setTimeout(() => {
              window.scrollTo({
                top: document.getElementById("contact-us")!.offsetTop,
                behavior: "smooth",
              });
            }, 100);
          }}
        >
          Оставить заявку
        </Button>
      </div>
    </div>
  );
}
