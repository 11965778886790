import { Box, createStyles } from "@mantine/core";
import { CompanySection } from "../components/CompanySection";
import { ContactUs } from "../components/ContactUs";
import { Features } from "../components/Features";
import { Partners } from "../components/Partners";
import { WeMakeBetterManagement } from "../components/landing/WeMakeBetterManagement";
import { WeMakeGrowth } from "../components/landing/WeMakeGrowth";
import { WeSaveMoney } from "../components/landing/WeSaveMoney";
import { WeSaveTime } from "../components/landing/WeSaveTime";
import { Welcome } from "../components/landing/Welcome";
import { MainLayout } from "../layouts/MainLayout";

const useStyles = createStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "inherit",
    maxWidth: "1000px",
    margin: "inherit",
  },

  padding: {
    padding: "0 24px",
  },

  contact_us: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "200px",
  },
}));

function LandingPage() {
  const { classes, cx } = useStyles();
  return (
    <MainLayout>
      <Welcome />
      <Box className={cx(classes.content, { [classes.padding]: true })}>
        <CompanySection />
        <Features />
        <WeSaveMoney />
        <WeSaveTime />
        <WeMakeBetterManagement />
        <WeMakeGrowth />
        <Partners />
      </Box>
      <Box className={classes.contact_us}>
        <ContactUs />
      </Box>
    </MainLayout>
  );
}

export default LandingPage;
