import { Box, createStyles, Text } from "@mantine/core";
import animation from "../../animations/23 - Start a business.json";
import Lottie from "lottie-react";
import useLanguage from "../../hooks/UseLanguage";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column-reverse",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
    gap: "20px",
    justifyContent: "center",
    textAlign: "left",
    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      textAlign: "center",
    },
  },

  title: {
    zIndex: 0,
    fontSize: "3rem",
    fontWeight: 700,

    [theme.fn.smallerThan("lg")]: {
      fontSize: "2rem",
    },
  },

  text: {
    zIndex: 0,
    fontSize: "1.7rem",
    fontWeight: 700,

    [theme.fn.smallerThan("lg")]: {
      fontSize: "1.2rem",
    },
  },
}));

const languages = {
  kaz: {
    title: "Жылдам дамуға көмектесеміз",
    description:
      "Біздің көмегімізбен сіз операцияларға емес стратегияға фокус бөлесіз. Біздің инновацияларымыз сізге конкуренттерден озуға және де жылдам дамуға көмегін береді.",
  },
  rus: {
    title: "Способствуем быстрому развитию",
    description:
      "С нашими передовыми решениями вы сосредоточитесь на стратегии, а не на операциях. Наши инновации помогут вашему бизнесу быстро развиваться и опережать конкуренцию.",
  },
};

export function WeMakeGrowth() {
  const { classes } = useStyles();
  const { language } = useLanguage();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Text
          className={classes.title}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan", deg: 75 }}
        >
          {languages[language].title}
        </Text>
        <Text className={classes.text}>{languages[language].description}</Text>
      </Box>
      <Lottie animationData={animation} />
    </Box>
  );
}
