import {
  createStyles,
  Title,
  SimpleGrid,
  Text,
  Button,
  ThemeIcon,
  Grid,
  Col,
  rem,
} from "@mantine/core";
import {
  IconBrush,
  IconDeviceDesktopAnalytics,
  IconTool,
  IconFileCode,
} from "@tabler/icons-react";
import useLanguage from "../hooks/UseLanguage";

const useStyles = createStyles((theme) => ({
  wrapper: {
    zIndex: 0,
    placeContent: "center",
  },

  title: {
    zIndex: 0,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(36),
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));

const languages = {
  kaz: {
    title: "Біз клиенттеріміз үшін кең аумақтағы қызметтер тізімін ұсынамыз.",
    description:
      "Біз клиенттерімге кең аумақтағы қызметтер тізімін ұсынамыз, сіздің бизнесіңізді интернетте дамытып және қолдаймыз.",
    features: [
      {
        icon: IconBrush,
        title: "Брендтинг және дизайн",
        description:
          "Бірегей фирмерлік стиль, логотип және басқа корпоративдік элементтерді әзірлеу",
      },
      {
        icon: IconFileCode,
        title: "IT-кеңес",
        description:
          "Клиенттің ағымдағы IT инфраструктурасын талдау, оптимизация және дамудың стратегиясын жасау, жаңа технологияларды енгізудегі ұсыныстар",
      },
      {
        icon: IconTool,
        title: "Техникалық қолдау және қамту",
        description:
          "IT жүйелерінің тұрақты жұмысын қамтамасыз ету, кез-келген мәселелерді уақытылы шешу және инциденттерді профилактикалау",
      },
      {
        icon: IconDeviceDesktopAnalytics,
        title: "CRM-жүйесін жасау және енгізу",
        description:
          "Клиенттермен байланыс іс-шараларын басқару жүйесін жасау, тапсырыстар, байланыс және аналитика мен жұмысты іске асыру",
      },
    ],
  },
  rus: {
    title: "Мы предлагаем обширный список услуг для наших клиентов",
    description:
      "Мы предоставляем широкий спектр услуг для наших клиентов, обеспечивая комплексные решения для развития и поддержки вашего бизнеса в интернете",
    features: [
      {
        icon: IconBrush,
        title: "Брендинг и дизайн",
        description:
          "Разработка уникального фирменного стиля, логотипа и других элементов корпоративной идентификации",
      },
      {
        icon: IconFileCode,
        title: "IT-консалтинг",
        description:
          "Анализ текущей IT-инфраструктуры клиента, выработка стратегии оптимизации и развития, рекомендации по внедрению новых технологий",
      },
      {
        icon: IconTool,
        title: "Техническая поддержка и сопровождение",
        description:
          "Обеспечение стабильной работы IT-систем, своевременное устранение возникающих проблем и профилактика возможных инцидентов",
      },
      {
        icon: IconDeviceDesktopAnalytics,
        title: "Разработка и внедрение CRM-системы",
        description:
          "Создание системы управления взаимоотношениями с клиентами, облегчающая работу с заказами, коммуникацией и аналитикой",
      },
    ],
  },
};

export function Features() {
  const { classes } = useStyles();
  const { language } = useLanguage();

  const items = languages[language].features.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: "blue", to: "cyan" }}
      >
        <feature.icon size={rem(26)} stroke={1.5} />
      </ThemeIcon>
      <Text fz="lg" mt="sm" fw={500}>
        {feature.title}
      </Text>
      <Text c="dimmed" fz="sm">
        {feature.description}
      </Text>
    </div>
  ));

  return (
    <div className={classes.wrapper}>
      <Grid>
        <Col span={12} md={5}>
          <Title
            className={classes.title}
            order={2}
            variant="gradient"
            gradient={{ from: "indigo", to: "cyan", deg: 135 }}
          >
            {languages[language].title}
          </Title>
          <Text c="dimmed">{languages[language].description}</Text>

          <Button
            variant="gradient"
            gradient={{ deg: 133, from: "blue", to: "cyan" }}
            size="lg"
            radius="md"
            mt="xl"
            onClick={(event) => {
              event.preventDefault();
              setTimeout(() => {
                window.scrollTo({
                  top: document.getElementById("contact-us")!.offsetTop,
                  behavior: "smooth",
                });
              }, 100);
            }}
          >
            Оставить заявку
          </Button>
        </Col>
        <Col span={12} md={7}>
          <SimpleGrid
            cols={2}
            spacing={30}
            breakpoints={[{ maxWidth: "md", cols: 1 }]}
          >
            {items}
          </SimpleGrid>
        </Col>
      </Grid>
    </div>
  );
}
