import { useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Paper,
  Transition,
  Burger,
  rem,
  Title,
  Box,
  Text,
  SimpleGrid,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import useLanguage from "../../hooks/UseLanguage";
import { LanguagePicker } from "../Language/LanguagePicker";

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  header: {
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    maxWidth: "1280px",
    margin: "auto",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    textAlign: "center",
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.xl,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  logo: {
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
  },

  languageCircle: {
    display: "flex",
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    border: "3px solid",
    alignItems: "center",
    justifyContent: "center",
    borderColor: theme.colors.gray[4],
  },

  languageWrapper: {
    display: "flex",
    gap: "20px",
  },

  selectedLanguage: {
    borderColor: theme.colors.blue[4],
  },

  menu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "80%",
    justifyContent: "space-between",
  },

  dropdown: {
    position: "fixed",
    top: HEADER_HEIGHT,
    height: "100%",
    paddingTop: "20px",
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

interface HeaderSimpleProps {
  links: { link: string; label: string }[];
}

const scaleY = {
  in: { opacity: 1, transform: "scaleY(1)" },
  out: { opacity: 0, transform: "scaleY(0)" },
  common: { transformOrigin: "top" },
  transitionProperty: "transform, opacity",
};

export function BaseHeader({ links }: HeaderSimpleProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();
  const { language, setLanguage } = useLanguage();

  const items = links.map((link) => (
    <a
      key={link.label}
      href={link.link}
      className={cx(classes.link, {
        [classes.linkActive]: active === link.link,
      })}
      onClick={(event) => {
        event.preventDefault();
        setTimeout(() => {
          window.scrollTo({
            top: document.getElementById(link.link)!.offsetTop - 100,
            behavior: "smooth",
          });
        }, 100);
        setActive(link.link);
      }}
    >
      {link.label}
    </a>
  ));

  return (
    <Header height={HEADER_HEIGHT} bg={"#000000FF"} fixed>
      <Box className={classes.header}>
        <a className={classes.logo} href={"/"}>
          <Title>tech-steppe</Title>
        </a>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>
        <LanguagePicker />

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              <Box className={classes.menu}>
                <SimpleGrid cols={1} spacing={"xl"}>
                  {items}
                </SimpleGrid>
                <Box className={classes.languageWrapper}>
                  <Box
                    className={cx(classes.languageCircle, {
                      [classes.selectedLanguage]: language === "kaz",
                    })}
                    onClick={() => {
                      setLanguage("kaz");
                    }}
                  >
                    <Text>Қаз</Text>
                  </Box>
                  <Box
                    className={cx(classes.languageCircle, {
                      [classes.selectedLanguage]: language === "rus",
                    })}
                    onClick={() => {
                      setLanguage("rus");
                    }}
                  >
                    <Text>Рус</Text>
                  </Box>
                </Box>
              </Box>
            </Paper>
          )}
        </Transition>
      </Box>
    </Header>
  );
}
