import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  rem,
  Space,
  Box,
  Input,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMutation } from "react-query";
import { ContactIconsList } from "./ContactIcons/ContactIcons";
import axios from "axios";
import { MessageModal } from "./MessageModal";
import { useDisclosure } from "@mantine/hooks";
import useLanguage from "../hooks/UseLanguage";
import { IMask, IMaskInput } from "react-imask";

const url = "https://api.techsteppe.kz";
const BOT_TOKEN = "6195400445:AAFUyJPz2vOHtbMIcke2EABmj1fynD6vmxY";

const useStyles = createStyles((theme) => ({
  wrapper: {
    zIndex: 0,
    boxSizing: "border-box",
    backgroundImage: "linear-gradient(135deg, #3746A4, #07E8F4)",
    borderRadius: theme.radius.md,
    padding: "40px",
    margin: "10px",
    width: "100%",
    maxWidth: "1000px",

    [theme.fn.smallerThan("md")]: {
      backgroundImage: "linear-gradient(15deg, #3746A4, #07E8F4)",
      padding: "10px",
    },
  },

  texts: {
    zIndex: 0,
    paddingTop: `calc(${theme.spacing.xl} * 1)`,
    paddingLeft: `calc(${theme.spacing.xl} * 1)`,
    paddingRight: `calc(${theme.spacing.xl} * 1)`,
  },

  title: {
    zIndex: 0,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    zIndex: 0,
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  form: {
    zIndex: 0,
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    zIndex: 0,
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    zIndex: 0,
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    zIndex: 0,
    color: theme.black,
  },

  control: {
    zIndex: 0,
    backgroundColor: theme.colors[theme.primaryColor][6],
  },

  container: {
    zIndex: 0,
    maxWidth: "80%",
    height: "70%",
    [theme.fn.smallerThan("lg")]: {
      height: "120%",
      maxWidth: "100%",
    },
  },
}));

export interface Form {
  phone_number: string;
  name: string;
  wishes: string;
}

async function Send(store: Form) {
  await axios.post(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`, {
    chat_id: "505853519",
    text: `name: ${store.name}\nphone_number: ${store.phone_number}\nproject: ${store.wishes}\n`,
  });
}

const languages = {
  kaz: {
    modal:
      "Сіздің өтінішіңіз бізге жетті. Біз сізге 24 сағат ішінде жауап береміз",
    title: "Өтініш қалдырыңыз",
    description:
      "Бізге телефон нөміріңізді және өтініштеріңізді қалдырыңыз, біз сізге 24 сағат ішінде жауап береміз",
    phone_number: "Телефон нөмірі",
    name: "Сіздің есіміңіз",
    text_area: "Сіздің өтініштеріңіз",
    text_area_input: "Tesla.com сайтына ұқсайтын сайт қалаймын",
    send: "Жіберу",
  },
  rus: {
    modal: "Ваши пожелания приняты. Мы ответим вам в течение 24 часов",
    title: "Оставьте заявку",
    description:
      "Оставьте нам ваш номер телефона и пожелания и мы ответим вам в течение 24 часов",
    phone_number: "Номер телефона",
    name: "Ваше имя",
    text_area: "Ваши пожелания",
    text_area_input: "Хочу сайт как у tesla.com",
    send: "Отправить",
  },
};

export function ContactUs() {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const { language } = useLanguage();

  const form = useForm({
    initialValues: {
      phone_number: "",
      name: "",
      wishes: "",
    },
  });

  const mutation = useMutation(
    async (data: Form) => {
      await Send(data);
    },
    {
      onSuccess: () => {
        open();
      },
      onError: (error: any) => {
        alert(JSON.stringify(error.response.data.error));
      },
    }
  );

  const onClick = async (data: Form) => {
    mutation.mutate(data);
  };

  return (
    <Box id="contact-us" className={classes.wrapper}>
      <MessageModal
        message={languages[language].modal}
        opened={opened}
        open={open}
        close={close}
      />
      <SimpleGrid
        cols={2}
        spacing={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        <Box className={classes.texts}>
          <Title className={classes.title}>{languages[language].title}</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            {languages[language].description}
          </Text>

          <ContactIconsList variant="white" />
          <Space h="3rem" />
        </Box>
        <Box className={classes.form}>
          <form onSubmit={form.onSubmit((values) => onClick(values))}>
            <Input.Wrapper
              label={languages[language].phone_number}
              classNames={{ label: classes.inputLabel }}
              withAsterisk
            >
              <Input
                id="phone_number"
                component={IMaskInput}
                mask="+7 (700) 000-00-00"
                placeholder="+7 (708) 871-12-38"
                required
                classNames={{ input: classes.input }}
                {...form.getInputProps("phone_number")}
              />
            </Input.Wrapper>

            <TextInput
              label={languages[language].name}
              placeholder="Tech Steppe"
              required
              mt="md"
              classNames={{ input: classes.input, label: classes.inputLabel }}
              {...form.getInputProps("name")}
            />
            <Textarea
              required
              label={languages[language].text_area}
              placeholder={languages[language].text_area_input}
              minRows={4}
              mt="md"
              classNames={{ input: classes.input, label: classes.inputLabel }}
              {...form.getInputProps("wishes")}
            />

            <Group position="right" mt="md">
              <Button
                className={classes.control}
                type="submit"
                loading={mutation.isLoading}
              >
                {languages[language].send}
              </Button>
            </Group>
          </form>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
