import React, { FC, useCallback } from "react";
import type { Container, Engine } from "tsparticles-engine";
import Particles from "react-tsparticles";
import { Footer } from "../components/footer/Footer";
import { BaseHeader } from "../components/header/header";
import { loadSlim } from "tsparticles-slim";
import { Box, createStyles } from "@mantine/core";
import useLanguage from "../hooks/UseLanguage";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "1280px",
    gap: "200px",
  },
}));

const languages = {
  kaz: {
    company_page: "Компания жайлы",
    projects: "Портфолио",
    contact_us: "Өтініш қалдыру",
  },
  rus: {
    company_page: "О нас",
    projects: "Портфолио",
    contact_us: "Оставить заявку",
  },
};

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { classes } = useStyles();
  const { language } = useLanguage();
  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await console.log(container);
    },
    []
  );

  const links = [
    {
      link: "company-page",
      label: languages[language].company_page,
    },
    {
      link: "projects",
      label: languages[language].projects,
    },
    {
      link: "contact-us",
      label: languages[language].contact_us,
    },
  ];

  return (
    <Box>
      <BaseHeader links={links} />
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#000000",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              // onClick: {
              //   enable: true,
              //   mode: "push",
              // },
              resize: true,
            },
            modes: {
              // push: {
              //   quantity: 4,
              // },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 3 },
            },
          },
          detectRetina: true,
        }}
      />
      <Box className={classes.wrapper}>{children}</Box>
      <Footer />
    </Box>
  );
};
