import React, { useState } from "react";
import {
  createStyles,
  UnstyledButton,
  Menu,
  Group,
  rem,
  Box,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { FlagComponent, KZ, RU } from "country-flag-icons/react/1x1";
import useLanguage from "../../hooks/UseLanguage";
import { Language } from "../../contexts/LanguageProvider";

const data: {
  id: Language;
  label: string;
  image: FlagComponent;
}[] = [
  { id: "kaz", label: "Қазақша", image: KZ },
  { id: "rus", label: "Русский", image: RU },
];

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  wrapper: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  control: {
    width: rem(150),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: "background-color 150ms ease",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  flag: {
    borderRadius: theme.radius.xl,
    width: "22px",
    height: "22px",
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

export function LanguagePicker() {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  const { language, setLanguage } = useLanguage();
  const [selected, setSelected] = useState(
    data.filter((item) => item.id === language)[0]
  );
  const items = data.map((item) => (
    <Menu.Item
      icon={<item.image className={classes.flag} />}
      onClick={() => {
        setSelected(item);
        setLanguage(item.id);
      }}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Box className={classes.wrapper}>
      <Menu
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        radius="md"
        width="target"
        withinPortal
        transitionProps={{ transition: "scale-y", duration: 150 }}
      >
        <Menu.Target>
          <UnstyledButton className={classes.control}>
            <Group spacing="xs">
              <selected.image className={classes.flag} />
              <span className={classes.label}>{selected.label}</span>
            </Group>
            <IconChevronDown
              size="1rem"
              className={classes.icon}
              stroke={1.5}
            />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>{items}</Menu.Dropdown>
      </Menu>
    </Box>
  );
}
