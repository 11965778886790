import { Modal } from '@mantine/core';

export function MessageModal({message, opened, open, close}: {message : string, opened : boolean, open : () => void, close : () => void}) {

  return (
    <>
      <Modal 
        opened={opened} 
        onClose={close} 
        withCloseButton={true} 
        centered 
        title={'Уведомление'}
      >
        {message}
      </Modal>
    </>
  );
}