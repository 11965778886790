import { createStyles, Box, Card, rem, getStylesRef } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    height: rem(180),
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],

    [`&:hover .${getStylesRef("image")}`]: {
      transform: "scale(1.15)",
    },
  },

  image: {
    ...theme.fn.cover(),
    ref: getStylesRef("image"),
    backgroundSize: "cover",
    transition: "transform 500ms ease",
    cursor: "pointer",
  },

  overlay: {
    position: "absolute",
    top: "50%",
    left: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer",
    backgroundImage:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .85) 120%)",
  },
}));

interface ArticleCardImageProps {
  image: string;
  link: string;
}

export function ImageCard({ image, link }: ArticleCardImageProps) {
  const { classes } = useStyles();

  return (
    <Card
      component="a"
      href={link}
      p="lg"
      shadow="lg"
      className={classes.card}
      radius="md"
    >
      <Box
        className={classes.image}
        style={{ backgroundImage: `url(${image})` }}
      />
      <Box className={classes.overlay} />
    </Card>
  );
}
