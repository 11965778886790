import { createContext, useState } from "react";

const languages = ["kaz", "rus"];

export type Language = "kaz" | "rus";

interface ILanguageContext {
  language: Language;
  setLanguage: (lang: Language) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
  language: (window.localStorage.getItem("language") ??
    languages[0]) as Language,
  setLanguage: () => {},
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [language, setLangFun] = useState<Language>(
    (window.localStorage.getItem("language") ?? languages[0]) as Language
  );

  const setLanguage = (language: Language) => {
    window.localStorage.setItem("language", language);
    setLangFun(language);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
