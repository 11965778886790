import { Center, createStyles, Text, SimpleGrid, Box } from "@mantine/core";
import useLanguage from "../../hooks/UseLanguage";
import { OutlineGradientButton } from "../OutlineGradientButton";
import Starfield from "./starfield";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
  },
  textWrapper: {
    display: "relative",
    bottom: 0,
    flexDirection: "column",
    gap: "20px",
    zIndex: 0,
    margin: "auto 0",
    alignItems: "center",
    padding: "0 24px",
  },

  title: {
    fontSize: "5rem",

    [theme.fn.smallerThan("md")]: {
      fontSize: "3rem",
    },
  },
}));

const languages = {
  kaz: {
    title: "Біз бизнес мәселелерін шешеміз",
    subtitle: "Cіздің бизнесті дамытуға және құруға IT көмек",
    portfolio: "Біздің жобалар",
    contact_us: "Өтініш қалдыру",
  },
  rus: {
    title: "Мы решаем бизнес проблемы",
    subtitle: "IT-помощь в развитии и создания вашего бизнеса",
    portfolio: "Наши проекты",
    contact_us: "Оставить заявку",
  },
};

export function Welcome() {
  const { classes, cx } = useStyles();
  const { language } = useLanguage();
  const height = window.innerHeight;
  return (
    <Box h={height} className={classes.wrapper}>
      <Starfield />
      <Box className={cx(classes.textWrapper)}>
        <Text
          className={classes.title}
          align="center"
          weight={700}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan", deg: 45 }}
        >
          {languages[language].title}
        </Text>
        <Text
          align="center"
          size="xl"
          weight={700}
          variant="gradient"
          gradient={{ from: "indigo", to: "cyan", deg: 45 }}
        >
          {languages[language].subtitle}
        </Text>
        <Center h={"100%"}>
          <SimpleGrid
            cols={2}
            spacing={50}
            pt={50}
            maw={"700px"}
            breakpoints={[{ maxWidth: "sm", cols: 1, verticalSpacing: "20px" }]}
          >
            <OutlineGradientButton
              targetId="projects"
              text={languages[language].portfolio}
            />
            <OutlineGradientButton
              targetId="contact-us"
              text={languages[language].contact_us}
            />
          </SimpleGrid>
        </Center>
      </Box>
    </Box>
  );
}
